.navbar > .container {
	justify-content: flex-end;
}

.navbar {
	background-color: black;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem;
	position: fixed;
	width: 100%;
	z-index: 1000;
	top: 0;
}


.logo {
	width: 100px;
	height: 100px;
}


.three-d-button {
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	border: 0;
	margin-left: 10px;
	vertical-align: middle;
	text-decoration: none;
	font-size: inherit;
	font-family: inherit;
		font-weight: 600;
		color: #000;
		text-transform: uppercase;
		padding: 0.5em 1em;
		background: #fcc800;
		z-index: 1000;
		border: 2px solid white;
		border-radius: 0.75em;
		transform-style: preserve-3d;
		transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);

		/* Apply ::before pseudo-element only on desktop screens */
		@media screen and (min-width: 768px) {
			&::before {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: #2b2a28;
				border-radius: inherit;
				box-shadow: 0 0 0 2px #fff, 0 0.625em 0 0 #fff;
				transform: translate3d(0, 0.5em, -1em);
				transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
			}

			&:hover {
				background: #fcc800;
				transform: translate(0, 0.25em);

				&::before {
					box-shadow: 0 0 0 2px #fff, 0 0.5em 0 0 #fff;
					transform: translate3d(0, 0.25em, -1em);
				}
			}

			&:active {
				background: #fcc800;
				transform: translate(0em, 0.5em);

				&::before {
					box-shadow: 0 0 0 2px #fff, 0 0.5em 0 0 #fff;
					transform: translate3d(0, 0, -1em);
				}
			}
		}
	}


.dropdown {
	display: none;
}

/* Dropdown Button */


.dropbtn {
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 10px;
}

.bar1,
.bar2,
.bar3 {
	width: 30px;
	height: 3px;
	background-color: white;
	margin: 6px 0;
	transition: 0.4s;
}

/* Rotate animation when clicked */
.change .bar1 {
	transform: rotate(-45deg) translate(-5px, 6px);
}

.change .bar2 {
	opacity: 0;
}

.change .bar3 {
	transform: rotate(45deg) translate(-5px, -6px);
}

@media (max-width: 768px) {
	button {
		margin-bottom: 10px;
	
	}

	.three-d-button {
		box-shadow: 0 4px 0 2px #2b2a28, 0 0.625em 0 0 #fff;
	}
}