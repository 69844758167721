.hero-banner {
/* /  background: url(../images/hero-banner.jpg) no-repeat center center; */
  background-size: cover;
  height: 30rem;
  position: relative;
  z-index: 1;
}

.home {
    margin-top: 200px;
}

.banner {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.banner-logo {
  width: 450px;
  height: 450px;
  margin: 1rem auto;
}

.title {
    font-size: 5rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin-top: 100px;
    font-family: "Urbanist";
}

.aboutUs {
    background-color: #fcc800;
    color: #fff;
    padding: 2rem 1rem;
    text-align: center;
    border-radius: 50px;
    margin: 1rem;
    padding-bottom: 10px; /* Adjust margin for better spacing */
    display: flex;
    flex-direction: row; /* Stack items vertically on small screens */
    align-items: center;
  }
 .aboutUs-title {
    color: #fcc800;
    margin-left: 3rem;
    margin-right: 3rem;
 }
 
 .aboutUs-title2 {
    color: black;
    font-weight: 600;
 }
  
  .aboutUs_container {
    display: flex;
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center;
    margin: 0 1rem; /* Adjust margin for better spacing */
    flex: 1;
    margin-bottom: 20px;
  }
  
  .aboutUS_text {
    text-align: center; /* Center text on small screens */
    margin: 1rem; /* Add spacing around the text */
    font-size: 1rem;
    font-family: 'Urbanist';
    font-weight: 400;
    line-height: 1.5;
  }
  
  .aboutUs-logoContainer {
    background-color: black;
    border-radius: 50px;
  }
  .aboutUs-logo {
    width: 200px; /* Adjust width for smaller screens */
    height: 200px; /* Adjust height for smaller screens */
    margin: 1rem auto; /* Center the logo with spacing */
  }

  .aboutUs-downArrow{
    color: white;
    font-size: 2rem;
    cursor: pointer;
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    .aboutUs {
      padding: 1.5rem 1rem;
      flex-direction: column; /* Adjust padding for smaller screens */
    }

    .aboutUs-title {
      text-align: center;
    }
  
    .aboutUS_text {
      font-size: 16px;
      text-align: justify;
      hyphens: auto;
      word-spacing: -0.5px;
    }
  
    .aboutUs-logo {
      width: 150px; /* Further adjust width for smaller screens */
      height: 150px; /* Further adjust height for smaller screens */
    }

    .banner-logo {
      width: 370px; /* Further adjust width for smaller screens */
      height: 370px;
    }
  }

.featureHeading{
    color: black;
    font-weight: 600;
}

.features-sub-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 2rem;
    flex: 1;
    margin-bottom: 20px;
    text-align: justify;

}
  .feature {
    width: 300px;
    min-width: 300px;
    min-height: 250px;
    margin-bottom: 20px;
    padding: 20px;
    background: #fcc800;
    border-radius: 10px;
    color: #f5f5f5;
    transition: transform 0.3s ease-in-out;
    transform: scale(1);
  }

  .feature:hover {
    transform: scale(1.1);
  }

  .featureDesc{
    font-size: 15px;
    text-align: left;
  }

  .feature-icon{
    width: 50px;
    height: 50px;
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    .feature {
      width: 100%;
       /* Display one feature per row on small screens */
    }

    .features-sub-container{
        margin-top: 0;
        margin-bottom: 0;
    }

    .featureHeading {
      margin-left: 5px;
    }

    .featureDesc {
      text-align: justify;
      hyphens: auto;
      word-spacing: -0.5px;
    }

    .featureHead {
      display: flex;
      align-items: center;
  }
  } 

  
  .bestSellerHeading {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .best-desc {
    color: white;
    margin-left: 3rem;
    margin-right: 3rem;
    font-size: 25px;
  }
  
  .bestSeller {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .bestSeller::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }
  
  .bestSeller::-webkit-scrollbar-thumb {
    background-color: white; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  .bestSeller::-webkit-scrollbar-track {
    background-color: #141615; /* Color of the scrollbar track */
  }
  
  .bestSellerItem {
    width: calc(50% - 10px);
    margin: 1rem;
    text-align: justify;
    background-color: black;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    transform: scale(1);
  }

  .bestSellerItem:hover {
    transform: scale(1.05);
  }
  
  .bestSellerImage {
    width: 330px;
    min-width: 250px;
    min-height: 250px;
    height: 250px;
    border-radius: 10px;
  }
  
  .bestSellerItemName {
    font-weight: bold;
    color: #fff;
    margin-top: 0.5rem;
  }
  
  .bestSellerItemPrice {
    font-weight: bold;
    color: #fcc800;
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    .bestSellerItem {
      width: 100%; /* Display one item per row on small screens */
    }

    .bestSeller {
        /* flex-direction: column; */
        margin: 0 2rem;
      }
  
    .bestSellerImage {
      width: 100%;
      height: auto;
      min-width: 250px;
      min-height: 250px; /* Automatically adjust height to maintain aspect ratio */
    }

    .best-desc{
      font-size: 1rem;
    }
  }
  

.zomato-container{
    margin: 2rem;
    background-color: #e23744;
    border-radius: 50px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.zomato-logo {
    color: white;
    font-size: 600px;
}

.orderNow {
    background-color: #141615;
    color: #fff;
    padding: 2rem 10rem;
    text-align: center;
}

.menu {
    margin: 2rem 0;
}

.menuContainer {
    display: flex;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-right: 3rem;
}
.menuLink {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menuDescription {
    color: #fff;
    font-size: 25px;
    font-weight: 400;
    text-align: justify;
}

.menuButton{
    color: black;
    font-size: 150px;
    border-radius: 50px;
    padding: 1rem;
    margin-right: 0.5rem;
    background: #fcc800;
}
.menuButton:hover {
    background-color: black;
    color: #fcc800;
}



.button-container {
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: center;
}
  
  .order-now-button {
    padding: 16px 32px;
    background-color: white;
    color: black;
    font-size: 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    /* Add 3D effect */
    transform: perspective(100px) rotateX(10deg);
    transition: transform 0.3s ease;
    cursor: pointer;
    border: 0;
    font-size: inherit;
    font-family: inherit;
  }
  
  .order-now-button:hover {
    /* Adjust the rotation value for a more noticeable 3D effect on hover */
    transform: perspective(100px) rotateX(20deg);
  } 

  .zomato-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 10px;
  }

  .zomato-display {
    display: none;
  }

  @media (max-width: 768px) {
    .menuContainer {
      flex-direction: column;
    }

    .menuButton{
        align-items: center;   
        margin: 10px;
    }
    .menulink {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .menuDescription {
      font-size: 1rem;
      hyphens: auto;
      word-spacing: -0.1px;
    }

    .order-now-button {
        font-size: 15px;
        transform: none;
        padding: 1rem;
    }

    .zomato-logo {
      font-size: 400px;
      color: white;
      height: 400px;
      width: 200px;
      margin-top: -150px;
    }

    .zomato-logo-container{
      margin: 30px;
      height: 100px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .zomato-container {
      justify-content: center;
      align-items: center;
      border-radius: 0%;
      margin-left: -10px;
      margin-right: -10px;
      height: 200px;
    }

    .zomato-button {
      align-items: center;
      width: 100%;
    }

    .zomato-display {
      display: flex;
    }

    .zomato-noDisplay {
      display: none;
    }
}

  .reels{
    background: #141615;
    color: #fff;
    padding: 1rem;
  }

  .ReelsContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ReelsItem{
    margin: 0 1rem;
  }

  
  .locationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
    justify-content: space-around;
  }
  
  .locationMap {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .locationDetails {
    width: 100%;
    max-width: 400px; /* Limit details width on larger screens */  
    border: 1px solid #fcc800;
    padding: 1%;
    background-color: #fcc800;
    border-radius: 5%;
  }
  .location-title{
    color: black;
    font-weight: 600;
  }
  .locationDetailsItem {
    margin-bottom: 1rem;
    text-align: justify;
    width: 300px;
  }
  .locationDetailsItemHeading{
    color: #141615;
    font-weight: 600;
    font-size: 25px;
  }
  .locationDetailsItemContent{
    color: #fff;
    font-weight: 400;
    font-size: 20px;
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    .locationContainer {
      align-items: stretch;
      flex-direction: column; /* Stretch items vertically on small screens */
    }
  
    .locationMap {
      max-width: 100%; /* Map width takes full width on small screens */
    }
  
    .locationDetails {
      display: flex;
      flex-direction: column;
      align-items: center; /* Center items horizontally on small screens */
    }
  
    .locationDetailsItem {
      text-align: justify;
    }

    .locationDetailsItemContent{
      font-size: 1rem;
      text-align: justify;
      hyphens: auto;
      word-spacing: -0.1px;
    }
  }

  .reviews {
    padding: 2rem;
    text-align: justify;
    background-color: #f5f5f5;
    color: #141615;
  }
  
  .reviewsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-top: 2rem;
  }
  
  .reviewsItem {
    border: 1px solid #ccc;
    padding: 1rem;
    margin-bottom: 1rem;
    min-width: 250px;
    min-height: 300px;
    text-decoration: none;
    color: #333;
    display: block;
    background: white;
    border-radius: 20px;
		transform-style: preserve-3d;
		transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);

    @media screen and (min-width: 768px) {

		&::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #c6c7c7;
			border-radius: inherit;
			box-shadow: -10px 5px 0 0 #c6c7c7;
			transform: translate3d(0, 0.5em, -1em);
			transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
		}

		&:hover {
			transform: translate(0, 0.25em);

			&::before {
				box-shadow: 0 0 0 2px #c6c7c7, -10px 5px 0 0 #c6c7c7;
				transform: translate3d(0, 0.25em, -1em);
			}
		}
	}
}

.star-rating {
  display: inline-block;
  font-size: 20px;
  color: gold;
}
  
  .reviewsItemHeading {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .reviewsItemContent{
    text-align: justify;  
}
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    .reviewsContainer {
      align-items: stretch;
    }
  
    .reviewsItem {
      padding: 1rem;
      box-shadow: -7px 5px 0 0 #c6c7c7;
    }

    .reviewsItemHeading {
      text-align: center;
    }
  }

  .review-desc {
    color: white;
    margin-left: 3rem;
    margin-right: 3rem;
    font-size: 25px;
  }
  
  .review {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .review::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }
  
  .review::-webkit-scrollbar-thumb {
    background-color: white; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  .review::-webkit-scrollbar-track {
    background-color: #141615; /* Color of the scrollbar track */
  }
  
  .reviewItem {
    width: calc(50% - 10px);
    margin: 1rem;
    text-align: justify;
    background-color: black;
    border-radius: 10px;
    min-width: 250px;
    min-height: 300px;
    transition: transform 0.3s ease-in-out;
    transform: scale(1);
  }

  .reviewItem:hover {
    transform: scale(1.05);
  }
  
  .bestSellerImage {
    width: 330px;
    height: 300px;
    border-radius: 10px;
  }
  
  .bestSellerItemName {
    font-weight: bold;
    color: #fff;
    margin-top: 0.5rem;
  }
  
  .bestSellerItemPrice {
    font-weight: bold;
    color: #fcc800;
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {

    .bestSeller {
        /* flex-direction: column; */
        margin: 0 2rem;
      }
  
    .bestSellerImage {
      width: 100%;
      height: auto; /* Automatically adjust height to maintain aspect ratio */
    }

    .reviewItem .reviewsItem {
      min-width: 250px;
      min-height: 350px;
    }
  }

  .footer {
    background-color: #fcc800;
    color: white;
    text-align: center;
    padding: 1rem;
  }
  
  .social-icons {
    margin-bottom: 1rem;
  }
  
  .social-icon {
    display: inline-block;
    margin: 0 0.5rem;
    color: black;
  }

  .social-icon:hover {
    color: #141615;
  }

  .bestSellerContainer{
    margin-bottom: 20px;
  }

  .social-icon img {
    width: 40px;
    height: 40px;
  }
  
  .copyright {
    font-size: 14px;
  }
.footer-logo-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
  .footer-logo {
    width: 20vw;
  }

  .info{
    display: flex;
  }

  .zomatoBike{
    width: 8vw;
    height: 16vh;
    border-radius: 10px;
  }

  .menuButtonBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    background: #fcc800;
    border-radius: 10px;
  }

  .menuButtonText{
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-align: center;
  }

  .credits{
    display: flex;
    margin: 2rem;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .footerItemContentItemHeading{
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .footer-logo {
      width: 30vw;
    }

    .credits{
      display: flex;
      margin: 2rem;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .footer-logo-div{
      flex-direction: column;
    }

    .info{
      flex-direction: column;
    }

    .zomatoBike{
      width: 30vw;
      height: auto;
      margin-bottom: 10px;
      border-radius: 0%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }